<template>
  <div class="d-flex justify-center" :class="{
    'view-fullscreen': fullscreen
  }">
    <div class="d-flex flex-column-reverse flex-md-row view-main file-view-wrapper" v-resize="updateZoom">
      <template v-if="getActiveStyle.grouped_items && Object.keys(getActiveStyle.grouped_items).length">
        <CadDesignBar v-if="getActiveDesignModule.slug === $config.project.modules.cadDesign"
                      :fullscreen="fullscreen" class="absolute view-gallery pr-1"
                      :ability-to-upload="abilityToUpload"
                      @createDesign="dropFiles($event.files, $event.newVersion)"/>

        <GalleryCarousel v-else :fullscreen="fullscreen" class="absolute view-gallery"
                         :ability-to-upload="abilityToUpload"
                         @createDesign="dropFiles($event.files, $event.newVersion)"/>
      </template>

      <div class="file-view" :class="` ${getActiveDesignMode}-mode`">
        <div v-if="getActiveFile" class="file d-flex flex-column" @dragenter="toggleForm">
          <v-flex v-if="getActiveFile.loading" class="file-loading d-flex justify-center align-center">
            <v-progress-circular
              :size="100"
              color="accent"
              indeterminate
            />
          </v-flex>
          <div class="modes-wrap d-flex flex-shrink-0 ma-auto overflow-hidden relative" :class="{
          'cursor-pointer-custom': getActiveDesignMode === $config.project.designModes.task
        }">
            <ViewMode/>
            <DrawMode :width="size.width" :height="size.height" :visible="showDesignElements" v-if="!onlyViewMode"
                      ref="canvas"
                      :zoom="zoom"
                      @imageSize="setImageSize"
                      @clickCanvas="addComment" @zoomChange="changeZoom" :minZoom="Math.min(...zoomParams)"
                      :maxZoom="Math.max(...zoomParams)" @move="setTransform"/>
            <TaskMode :zoom="zoom" v-if="!onlyViewMode"
                      :transform="currentTransform"
                      :minZoom="Math.min(...zoomByImageSize)"
                      :class="[showDesignElements ? 'd-flex' : 'd-none']"
                      @viewComment="centerCanvasToComment"
                      @wheel="wheelCanvasElements"
                      ref="task"/>


          </div>
          <UiProjectStatus :id="getActiveFile.status" block/>
        </div>

        <v-responsive v-else :aspect-ratio="878/652">
          <UIFilesUploader
            :files-formats="$config.project.modulesFileFormats[getActiveDesignModule.slug]"
            :max-file-size="$config.filesystem.project.design.file.maxUploadFileSize"
            :disabled="!abilityToUpload"
            multiple
            @onChange="dropFiles($event, false)"
            class="file file-view-upload-form d-flex flex-column align-center justify-center text-center overflow-hidden"
          >
            <template v-if="abilityToUpload">
              <IconUpload width="48" class="accent--text"/>
              <p class="mt-8 mb-0 accent--text text-uppercase font-weight-semi-bold">Upload {{
                  getActiveDesignModule.title
                }}</p>
            </template>
            <template v-if="!abilityToUpload">
              <UnavailableModuleInfo v-if="!getProject?.scheme?.settings[activeModuleWorkflowOpenTypeSlug] && !this.getActiveStyle.ready_to_work" class="unavailable-module" @updateModuleId="$emit('updateModuleId', $event)" />
              <p v-else class="mt-8 mb-0 primary--text text-uppercase text-center font-weight-semi-bold">
                You don't have permissions to upload files into {{ getActiveDesignModule.title }} module
              </p>
            </template>

          </UIFilesUploader>
        </v-responsive>
        <PortalTarget name="comment-portal-target" class="absolute inset-0 pointer-events-none z-10" v-show="showDesignElements"/>
        <div class="absolute inset-0 pointer-events-none z-10" v-if="getActiveFile">
          <v-tooltip :left="fullscreen" :right="!fullscreen" color="rgba(47, 49, 53, 0.7)" z-index="99">
            <template #activator="{attrs, on}">
              <UiBtn class="fullscreen-control pointer-events-auto" color="accent" v-bind="attrs" v-on="on" fab outlined
                     @click="fullscreen = !fullscreen">
                <IconMaximize width="16"/>
              </UiBtn>
            </template>
            <div class="text-captions-1">
              {{ fullscreen ? 'Exit full screen' : 'Fullscreen' }}
            </div>
          </v-tooltip>
          <div class="zoom-controls space-y-1 pointer-events-auto" v-if="getActiveFile && !onlyViewMode && isImage">
            <v-tooltip :left="fullscreen" :right="!fullscreen" color="rgba(47, 49, 53, 0.7)" z-index="99">
              <template #activator="{attrs, on}">
                <UiBtn color="accent" fab outlined v-bind="attrs" v-on="on" @click="zoomHandler('plus')">
                  <IconPlus width="16"/>
                </UiBtn>
              </template>
              <div class="text-captions-1">
                Zoom in
              </div>
            </v-tooltip>
            <v-tooltip :left="fullscreen" :right="!fullscreen" color="rgba(47, 49, 53, 0.7)" z-index="99">
              <template #activator="{attrs, on}">
                <UiBtn color="accent" fab outlined v-bind="attrs" v-on="on" @click="zoomHandler('minus')">
                  <IconMinus width="16"/>
                </UiBtn>
              </template>
              <div class="text-captions-1">
                Zoom out
              </div>
            </v-tooltip>
          </div>
        </div>
        <v-responsive v-show="showUploadForm" :aspect-ratio="878/652">
          <UIFilesUploader
            :files-formats="$config.project.modulesFileFormats[getActiveDesignModule.slug]"
            :max-file-size="$config.filesystem.project.design.file.maxUploadFileSize"
            :disabled="!abilityToUpload"
            multiple
            @onChange="dropFiles($event, false)"
            @dragleave="toggleForm"
            class="file-view-upload-overlay-form pa-12"
          >
            <div class="d-flex flex-column align-center justify-center gray-0--text font-weight-medium">
              <IconUpload width="48" class="mb-4"/>
              Drag and drop or browse
              <span class="mt-2 font-weight-regular">click here</span>
            </div>
          </UIFilesUploader>
        </v-responsive>
        <v-flex
          v-if="fileLoading"
          class="z-1 white file-loading fill-height fill-width absolute inset-0 d-flex justify-center align-center"
        >
          <v-progress-circular
            :size="100"
            color="accent"
            indeterminate
          />
        </v-flex>
      </div>
      <div class="d-flex flex-row flex-md-column"
           v-if="getActiveFile"
           :class="[$vuetify.breakpoint.mdAndUp ?  'space-y-3 ml-3' : 'space-x-3 mb-3', fullscreen ? 'py-3' : '']">
        <ActiveFileStatusIndicator v-if="getActiveFile" :disabled="latestVersion > getActiveFile.version"/>
        <template v-if="getActiveFile && !onlyViewMode">
          <v-tooltip :left="fullscreen" :right="!fullscreen" color="rgba(47, 49, 53, 0.7)"
                     v-if="getPermission($route.params.project_id).design['can-edit-freehand-design']">
            <template #activator="{attrs, on}">
              <div v-bind="attrs"
                   v-on="on">
                <UiBtn
                  icon
                  :disabled="!getActiveFile || getDrawModeHistory.loading || getDrawModeHistory.index <= 0"
                  @click="showDesignElements = true; $eventBus.$emit('drawModeHistoryEvent', {eventType: 'stepBack'})"
                >
                  <IconArrowRotateLeft width="18"/>
                </UiBtn>
              </div>
            </template>
            <div class="text-captions-1">
              Undo
            </div>
          </v-tooltip>

          <v-tooltip :left="fullscreen" :right="!fullscreen" color="rgba(47, 49, 53, 0.7)"
                     v-if="getPermission($route.params.project_id).design['can-edit-freehand-design']">
            <template #activator="{attrs, on}">
              <div v-bind="attrs"
                   v-on="on">
                <UiBtn
                  icon
                  :disabled="getDrawModeHistory.loading || getDrawModeHistory.steps.length === getDrawModeHistory.index"
                  @click="showDesignElements = true; $eventBus.$emit('drawModeHistoryEvent', {eventType: 'stepForward'})"
                >
                  <IconArrowRotateRight width="18"/>
                </UiBtn>
              </div>
            </template>
            <div class="text-captions-1">
              Redo
            </div>
          </v-tooltip>

          <UiMenu
            v-if="getPermission($route.params.project_id).design['can-edit-freehand-design']"
            v-model="textOptionsMenu"
            left
            offset-x
            min-width="228"
            :nudge-left="8"
            :nudge-top="8"
            :close-on-content-click="false"
            content-class="draw-controls-menu"
          >
            <template #activator="{on, attrs}">
              <v-tooltip :left="fullscreen" :right="!fullscreen" color="rgba(47, 49, 53, 0.7)">
                <template #activator="{attrs:attrsTooltip, on:onTooltip}">
                  <UiBtn
                    v-if="getPermission($route.params.project_id).design['can-edit-freehand-design']"
                    v-bind="{...attrs, ...attrsTooltip}"
                    v-on="{...on, ...onTooltip}"
                    :color="getActiveDesignMode === $config.project.designModes.draw && getDrawModeData.mode === $config.project.drawModes.text && showDesignElements ? 'accent' : 'gray-60'"
                    icon
                    @click="toggleMode($config.project.designModes.draw); $store.dispatch('updateDrawModeData', {mode: $config.project.drawModes.text})"
                  >
                    <IconText width="18"/>
                  </UiBtn>
                </template>
                <div class="text-captions-1">
                  Add text
                </div>
              </v-tooltip>
            </template>

            <div class="pt-2 px-3 pb-3">
              <div class="d-flex align-center justify-space-between mb-1">
                <h6 class="text-body font-weight-semi-bold">Color</h6>

                <UiBtn icon class="mr-n2" width="24" height="24" @click="textOptionsMenu = false">
                  <IconCancel width="14" class="gray-60--text"/>
                </UiBtn>
              </div>

              <div class="draw-mode-colors d-flex align-center justify-center mb-2 py-1 px-2 border rounded">
                <div
                  v-for="(color, idx) in $config.project.drawModeColors"
                  :key="idx"
                  :style="{color}"
                  :class="{'is-active': color === getDrawModeData.color}"
                  @click="$store.dispatch('updateDrawModeData', {color})"
                />
              </div>

              <div class="d-flex align-center justify-space-between mb-2">
                <h6 class="text-body font-weight-semi-bold">Size</h6>
              </div>

              <div
                class="draw-mode-size text-size relative d-flex align-center justify-center mb-2 py-1 border rounded">
                <div class="draw-mode-size-dot d-flex justify-center align-center gray-0--text">A</div>
                <v-slider
                  v-model="fontSize"
                  :step="1"
                  :min="20"
                  :max="120"
                  track-color="gray-60"
                  track-fill-color="gray-60"
                  thumb-label
                  hide-details
                  @input="$store.dispatch('updateDrawModeData', {fontSize})"
                />
                <div class="draw-mode-size-dot d-flex justify-center align-center gray-0--text">A</div>
              </div>
            </div>
          </UiMenu>

          <UiMenu
            v-if="getPermission($route.params.project_id).design['can-edit-freehand-design']"
            v-model="uploadDrawModeImageMenu"
            left
            offset-x
            min-width="268"
            :nudge-left="8"
            :nudge-top="8"
            :close-on-content-click="false"
            content-class="draw-controls-menu"
          >
            <template #activator="{on, attrs}">
              <v-tooltip :left="fullscreen" :right="!fullscreen" color="rgba(47, 49, 53, 0.7)">
                <template #activator="{attrs:attrsTooltip, on:onTooltip}">
                  <UiBtn
                    v-bind="{...attrs, ...attrsTooltip}"
                    v-on="{...on, ...onTooltip}"
                    icon
                    @click="toggleMode($config.project.designModes.draw)"
                  >
                    <IconMedia width="20"/>
                  </UiBtn>
                </template>
                <div class="text-captions-1">
                  Add image
                </div>
              </v-tooltip>
            </template>

            <div class="pt-2 px-3 pb-3">
              <div class="d-flex align-center justify-space-between mb-2">
                <h6 class="text-body text-capitalize font-weight-semi-bold">Add image</h6>

                <UiBtn icon class="mr-n2" width="24" height="24" @click="uploadDrawModeImageMenu = false">
                  <IconCancel width="14" class="gray-60--text"/>
                </UiBtn>
              </div>

              <UIFilesUploader
                :files-formats="$config.filesystem.project.design.drawImage.acceptedUploadFormats"
                :max-file-size="$config.filesystem.project.design.drawImage.maxUploadFileSize"
                multiple
                @onChange="dropDrawImage"
                class="upload-draw-image-form d-flex flex-column align-center justify-center cursor-pointer"
              >
                <IconUpload width="26" class="mb-3 accent--text"/>
                <p class="text-body font-weight-light gray-60--text">Drag and Drop here</p>
                <span class="text-body font-weight-medium accent--text">or browse to add</span>
              </UIFilesUploader>
            </div>
          </UiMenu>

          <v-tooltip :left="fullscreen" :right="!fullscreen" color="rgba(47, 49, 53, 0.7)"
                     v-if="getPermission($route.params.project_id).design['can-edit-freehand-design']">
            <template #activator="{attrs, on}">
              <UiBtn
                :color="getActiveDesignMode === $config.project.designModes.draw && getDrawModeData.mode === $config.project.drawModes.freehand && showDesignElements? 'accent' : 'gray-60'"
                icon
                v-bind="attrs"
                v-on="on"
                @click="toggleMode($config.project.designModes.draw); $store.dispatch('updateDrawModeData', {mode: $config.project.drawModes.freehand})"
              >
                <IconMove width="16"/>
              </UiBtn>
            </template>
            <div class="text-captions-1">
              Move
            </div>
          </v-tooltip>

          <v-tooltip :left="fullscreen" :right="!fullscreen" color="rgba(47, 49, 53, 0.7)">
            <template #activator="{attrs, on}">
              <UiBtn
                :color="getActiveDesignMode === $config.project.designModes.task && showDesignElements ? 'accent' : 'gray-60'"
                icon
                v-bind="attrs"
                v-on="on"
                @click="toggleMode($config.project.designModes.task); $store.dispatch('updateDrawModeData', {mode: ''})"
              >
                <IconChat width="16"/>
              </UiBtn>
            </template>
            <div class="text-captions-1">
              Add comment
            </div>
          </v-tooltip>

          <UiMenu
            v-if="getPermission($route.params.project_id).design['can-edit-freehand-design']"
            v-model="pathOptionsMenu"
            left
            offset-x
            min-width="228"
            :nudge-left="8"
            :nudge-top="8"
            :close-on-content-click="false"
            content-class="draw-controls-menu"
          >
            <template #activator="{on, attrs}">
              <v-tooltip :left="fullscreen" :right="!fullscreen" color="rgba(47, 49, 53, 0.7)">
                <template #activator="{attrs:attrsTooltip, on:onTooltip}">
                  <UiBtn
                    v-if="getPermission($route.params.project_id).design['can-edit-freehand-design']"
                    v-bind="{...attrs, ...attrsTooltip}"
                    v-on="{...on, ...onTooltip}"
                    :color="getActiveDesignMode === $config.project.designModes.draw && getDrawModeData.mode === $config.project.drawModes.path && showDesignElements ? 'accent' : 'gray-60'"
                    icon
                    @click="toggleMode($config.project.designModes.draw); $store.dispatch('updateDrawModeData', {mode: $config.project.drawModes.path})"
                  >
                    <IconPenCurved width="18"/>
                  </UiBtn>
                </template>
                <div class="text-captions-1">
                  Draw
                </div>
              </v-tooltip>
            </template>

            <div class="pt-2 px-3 pb-3">
              <div class="d-flex align-center justify-space-between mb-1">
                <h6 class="text-body font-weight-semi-bold">Color</h6>

                <UiBtn icon class="mr-n2" width="24" height="24" @click="pathOptionsMenu = false">
                  <IconCancel width="14" class="gray-60--text"/>
                </UiBtn>
              </div>

              <div class="draw-mode-colors d-flex align-center justify-center mb-2 py-1 px-2 border rounded">
                <div
                  v-for="(color, idx) in $config.project.drawModeColors"
                  :key="idx"
                  :style="{color}"
                  :class="{'is-active': color === getDrawModeData.color}"
                  @click="$store.dispatch('updateDrawModeData', {color})"
                />
              </div>

              <div class="d-flex align-center justify-space-between mb-2">
                <h6 class="text-body font-weight-semi-bold">Size</h6>
              </div>

              <div class="draw-mode-size relative d-flex align-center justify-center mb-2 py-1 px-2 border rounded">
                <div class="draw-mode-size-dot"/>
                <v-slider
                  v-model="brush"
                  :step="1"
                  :min="1"
                  :max="20"
                  track-color="gray-60"
                  track-fill-color="gray-60"
                  thumb-label
                  hide-details
                  @input="$store.dispatch('updateDrawModeData', {brush})"
                />
                <div class="draw-mode-size-dot"/>
              </div>
            </div>
          </UiMenu>

          <v-tooltip :left="fullscreen" :right="!fullscreen" color="rgba(47, 49, 53, 0.7)">
            <template #activator="{attrs, on}">
              <UiBtn
                :color="showDesignElements ? 'accent' : 'gray-60'"
                icon
                v-bind="attrs"
                v-on="on"
                @click="toggleViewMode"
              >
                <IconEye width="18"/>
              </UiBtn>
            </template>
            <div class="text-captions-1">
              {{ showDesignElements ? 'Hide all' : 'Show all' }}
            </div>
          </v-tooltip>

          <v-tooltip :left="fullscreen" :right="!fullscreen" color="rgba(47, 49, 53, 0.7)">
            <template #activator="{attrs, on}">
              <UiBtn
                :color="getActiveDesignMode === $config.project.designModes.hand && showDesignElements &&  !spacePressed ? 'accent' : 'gray-60'"
                icon
                v-bind="attrs"
                v-on="on"
                @click="toggleHandMode"
              >
                <IconHandfree width="16"/>
              </UiBtn>
            </template>
            <div class="text-captions-1">
              Hand
            </div>
          </v-tooltip>
        </template>
      </div>
    </div>
  </div>


</template>

<script>
import {mapGetters} from "vuex";
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import ActiveFileStatusIndicator from "./ActiveFileStatusIndicator";
import ViewMode from "./modes/ViewMode";
import TaskMode from "./modes/TaskMode";
import DrawMode from "./modes/DrawMode";
import UiBtn from "@/components/UI/UiBtn";
import UiProjectStatus from "@/components/UI/UiProjectStatus";
import UiMenu from "@/components/UI/UiMenu";
import {BASE_HEIGHT, BASE_WIDTH} from "@/config";
import {filterFieldElement, generateDoubledNumbersArray, getFileName} from "@/utils/helpers";
import GalleryCarousel from "@/views/project/design-view/GalleryCarousel";
import CadDesignBar from "@/views/project/design-view/CadDesignBar.vue";
import {getFileExtension} from "@/utils/helpers";
import {PortalTarget} from 'portal-vue'
import UnavailableModuleInfo from "@/views/project/design-view/UnavailableModuleInfo.vue";

export default {
  name: 'FileView',
  components: {
    PortalTarget,
    GalleryCarousel,
    CadDesignBar,
    IconUpload: () => import('@/components/icons/IconUpload'),
    IconChat: () => import('@/components/icons/IconChat'),
    IconEye: () => import('@/components/icons/IconEye'),
    IconText: () => import('@/components/icons/IconText'),
    IconPenCurved: () => import('@/components/icons/IconPenCurved'),
    IconMedia: () => import('@/components/icons/IconMedia'),
    IconArrowRotateLeft: () => import('@/components/icons/IconArrowRotateLeft'),
    IconArrowRotateRight: () => import('@/components/icons/IconArrowRotateRight'),
    IconMove: () => import('@/components/icons/IconMove'),
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconPlus: () => import('@/components/icons/IconPlus'),
    IconMinus: () => import('@/components/icons/IconMinus'),
    IconHandfree: () => import('@/components/icons/IconHandfree'),
    IconMaximize: () => import('@/components/icons/IconMaximize'),

    UiMenu,
    UiProjectStatus,
    UiBtn,
    UIFilesUploader,
    ActiveFileStatusIndicator,
    ViewMode,
    TaskMode,
    DrawMode,
    UnavailableModuleInfo,
  },
  props: {
    abilityToUpload: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: BASE_WIDTH
    },
    height: {
      type: Number,
      default: BASE_HEIGHT
    },
    fileLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showUploadForm: false,
      uploadDrawModeImageMenu: false,
      pathOptionsMenu: false,
      textOptionsMenu: false,
      brush: this.$store.getters.getDrawModeData.brush,
      fontSize: this.$store.getters.getDrawModeData.fontSize,
      showDesignElements: true,
      prevViewMode: this.$config.project.designModes.view,
      prevDrawMode: this.$config.project.drawModes.freehand,
      // zoomParams: [0.25, 0.75, 1, 1.1, 1.25, 1.5, 1.75, 2],
      zoomIdx: 4,
      zoom: 1,
      currentTransform: {
        x: 0,
        y: 0
      },
      commentPosition: null,
      fullscreen: false,
      spacePressed: false,
      initGallery: true,
      imageSize: {width: 1, height: 1},
      baseZoom: [0.25, 0.5, 0.75, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2]
    }
  },
  computed: {
    ...mapGetters([
      'getActiveStyle',
      'getActiveFile',
      'getActiveDesignModule',
      'getActiveDesignMode',
      'getDrawModeData',
      'getDrawModeHistory',
      'getPermission',
      'getProject',
    ]),
    activeModuleWorkflowOpenTypeSlug() {
      return this.$config.project.workflowOpenTypesById[this.getActiveDesignModule?.workflow_type]
    },
    zoomByImageSize() {
      const scaleCoef = this.imageSize.height > this.imageSize.width ? this.imageSize.height / this.imageSize.width : this.imageSize.width / this.imageSize.height

      const maxZoom = Math.max(...this.baseZoom)

      if (scaleCoef > 1.5) {
        const nextZoom = generateDoubledNumbersArray(maxZoom * 2, maxZoom * 2 * scaleCoef)
        return [...this.baseZoom, ...nextZoom]
      }
      return this.baseZoom
    },
    onlyViewMode() {
      const fileExt = `.${getFileName(this.getActiveFile.file.original_name).ext}`
      return this.$config.filesystem.fileTypes.pdf.includes(fileExt) || this.$config.filesystem.fileTypes.xls.includes(fileExt);
    },
    zoomParams() {
      const scale = BASE_HEIGHT / this.$vuetify.breakpoint.height;

      return this.fullscreen ? this.zoomByImageSize.map(zoom => zoom / scale) : this.zoomByImageSize
    },
    size() {
      const RIGHT_BAR_SIZE = 54
      const LEFT_SIZE = 146
      const STATUS_BAR_SIZE = 22
      const scale = Math.max(BASE_WIDTH / this.$vuetify.breakpoint.width, BASE_HEIGHT / this.$vuetify.breakpoint.height);
      return this.fullscreen ? {
        width: Math.min(this.$vuetify.breakpoint.width - RIGHT_BAR_SIZE - LEFT_SIZE, BASE_WIDTH / scale),
        height: this.$vuetify.breakpoint.height - STATUS_BAR_SIZE,
      } : {
        width: this.$vuetify.breakpoint.width > 1500 ? BASE_WIDTH : BASE_WIDTH * 0.83,
        height: this.$vuetify.breakpoint.width > 1500 ? BASE_HEIGHT : BASE_HEIGHT * 0.83,
      }
    },
    isImage() {
      return this.$config.filesystem.fileTypes.image.includes(getFileExtension(this.getActiveFile.file.original_name))
    },
    activeGroupFiles() {
      return this.getActiveStyle?.items?.filter(file => +file.gallery_group_id === +this.$route.query.gallery_group_id) || []
    },
    latestVersion() {
      return Math.max(...this.activeGroupFiles.map(file => file.version))
    }
  },
  watch: {
    'getDrawModeData': {
      handler(newVal) {
        this.brush = newVal.brush;
        this.fontSize = newVal.fontSize;
      }
    },
    getActiveFile(newVal) {
      if (!newVal) {
        this.fullscreen = false
      }
    },
    fullscreen() {
      this.updateZoom()
    }
  },
  mounted() {
    document.addEventListener('keydown', this.keyPress)
    document.addEventListener('keyup', this.keyPress)
  },
  created() {
    const {view_mode} = this.$route.query;
    const modes = Object.values(this.$config.project.designModes);

    if (this.getPermission(this.$route.params.project_id).design['can-edit-freehand-design']) {
      if ((view_mode !== '' || view_mode !== undefined) && modes.includes(view_mode)) {
        this.$store.state.ProjectDesign.activeDesignMode = view_mode;
        if (view_mode === this.$config.project.designModes.draw) {
          this.$store.dispatch('updateDrawModeData', {mode: this.$config.project.drawModes.freehand});
        }
        if (view_mode === this.$config.project.designModes.hand) {
          this.$store.dispatch('updateDrawModeData', {mode: this.$config.project.drawModes.handmode});
        }
      }
    } else {
      this.$store.dispatch('updateDrawModeData', {mode: this.$config.project.drawModes.handmode});
      this.$store.state.ProjectDesign.activeDesignMode = this.$config.project.designModes.hand;
      this.$router.$updateQueryParams({view_mode: this.$config.project.designModes.hand});
    }

  },
  beforeDestroy() {
    this.$store.state.ProjectDesign.activeDesignMode = this.$config.project.designModes.view;
    document.removeEventListener('keydown', this.keyPress)
    document.removeEventListener('keyup', this.keyPress)
  },
  methods: {
    setTransform(e) {
      this.currentTransform.x = e[4]
      this.currentTransform.y = e[5]
    },
    centerCanvasToComment(e) {
      this.$refs.canvas.panCenter(e.x, e.y)
    },
    keyPress(e) {
      if (!filterFieldElement(e)) return
      if (e.type === 'keydown') {
        if (e.ctrlKey || e.metaKey) {
          if (e.code === 'Equal' || e.code === 'NumpadAdd') {
            e.preventDefault()
            this.zoomHandler('plus')
          }

          if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
            e.preventDefault()
            this.zoomHandler('minus')
          }
        }
        if (e.code === 'KeyH') {
          e.preventDefault()
          this.toggleHandMode()
        }
        if (e.code === 'Escape') {
          e.preventDefault()
          this.fullscreen = false;
        }
        if (e.code === 'Space') {
          e.preventDefault()
          if (this.spacePressed || this.getActiveDesignMode === this.$config.project.designModes.hand) return;
          this.spacePressed = true
          this.toggleHandMode()
        }
      } else {
        if (e.code === 'Space') {
          e.preventDefault()
          if (!this.spacePressed) return;
          if (this.getActiveDesignMode === this.$config.project.designModes.hand) {
            this.toggleHandMode()
          }
          this.spacePressed = false
        }
      }
    },
    toggleHandMode() {
      if (this.getActiveDesignMode !== this.$config.project.designModes.hand) {
        this.prevViewMode = this.getActiveDesignMode
        this.prevDrawMode = this.getDrawModeData.mode
      }
      this.toggleMode(this.getActiveDesignMode === this.$config.project.designModes.hand ? this.prevViewMode : this.$config.project.designModes.hand);
      this.$store.dispatch('updateDrawModeData', {mode: this.getActiveDesignMode === this.$config.project.designModes.hand ? this.$config.project.drawModes.handmode : this.prevDrawMode})
    },
    nearestNumber() {
      const zoom = this.$refs.canvas.canvas.getZoom()

      return Math.min(...this.zoomParams.filter(v => v >= zoom))
    },
    zoomHandler(mode) {
      this.zoomIdx = this.zoomParams.findIndex(i => i === this.nearestNumber())
      switch (mode) {
        case 'plus':
          this.zoom = this.zoomParams[this.zoomIdx < this.zoomParams.length - 1 ? this.zoomIdx + 1 : this.zoomParams.length - 1]
          this.zoomIdx = this.zoomIdx < this.zoomParams.length - 1 ? this.zoomIdx + 1 : this.zoomParams.length - 1
          break;
        case 'minus':
          this.zoom = this.zoomParams[this.zoomIdx ? this.zoomIdx - 1 : 0]
          this.zoomIdx = this.zoomIdx ? this.zoomIdx - 1 : 0
          break;
        default:
          this.zoom = 1
      }
      if (this.isImage) {
        this.$refs.canvas.setZoom(this.zoom)
      }
    },

    dropFiles(files, newVersion) {
      if (!this.abilityToUpload) return;

      this.$emit('createDesign', {files, newVersion});
      this.showUploadForm = false;
    },
    toggleForm() {
      if (!this.abilityToUpload || this.getActiveDesignMode === this.$config.project.designModes.draw) return;
      this.showUploadForm = !this.showUploadForm;
    },
    toggleMode(mode, show = true) {
      this.showDesignElements = show

      this.$store.state.ProjectDesign.activeDesignMode = mode;
      this.$router.$updateQueryParams({view_mode: mode});
    },
    toggleViewMode() {
      if(this.showDesignElements) {
        this.toggleMode(this.$config.project.designModes.hand);
        this.$store.dispatch('updateDrawModeData', {mode: this.$config.project.drawModes.freehand})
      }
      this.showDesignElements = !this.showDesignElements
    },
    dropDrawImage(images) {
      this.$eventBus.$emit('addStyleDrawModeImages', images);
      this.uploadDrawModeImageMenu = false;
    },
    addComment(e) {
      this.$refs.task.addItem(e)
    },
    setImageSize(sizes) {
      this.imageSize = sizes
    },
    changeZoom(zoom) {
      this.zoom = zoom

      this.zoomIdx = this.zoomParams.findIndex(i => i === this.nearestNumber())
      if (this.zoomIdx > this.zoomParams.length - 1) {
        this.zoomIdx = this.zoomParams.length - 1
      }
      if (this.zoomIdx < 0) {
        this.zoomIdx = 0
      }
    },
    updateZoom() {
      this.$nextTick(() => {
        this.$refs.canvas?.setZoom?.(this.zoomParams[this.zoomIdx])
      })
    },
    wheelCanvasElements(coordinates) {
      this.$refs.canvas.scrollCanvas(coordinates)
    },
  }
}
</script>


<style scoped lang="scss">
.file-view-wrapper {
  position: relative;
  padding-left: 146px;
  margin-left: -146px;
  @media screen and (max-width: 1500px) {
    margin-left: 0;
  }
}

.view-gallery {
  top: 0;
  left: 0;
  width: 138px;
  height: 100%;

  scrollbar-color: auto;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #FFFFFF;
    border: 1px solid #DDDDDD !important;
    box-sizing: border-box;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #81848B !important;
    border: 2px solid transparent !important;
    border-radius: 12px !important;
    background-clip: content-box;
  }
}

.border {
  border: 1px solid var(--v-gray-30-base);
}

:deep(.cursor-pointer-custom) {
  .canvas-wrap {
    cursor: inherit;

    canvas {
      cursor: inherit !important;
    }
  }
}

.file-view {
  position: relative;
  width: calc(v-bind('size.width') * 1px);
  height: calc(v-bind('size.height') * 1px);
  z-index: 1;

  .file {
    overflow: auto;
    background: url("~@/assets/images/light/background/bg-grid-view.png") repeat;

    .modes-wrap {
      width: calc(v-bind('size.width') * 1px);
      height: calc(v-bind('size.height') * 1px);
      z-index: 1;
    }
  }

  .file-loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .file-view-upload-form {
    position: relative;
    border: 1px dashed var(--v-accent-base) !important;
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 6px;
    cursor: pointer;
    font-size: 13px;
    letter-spacing: 0.2em;
  }

  .file-view-upload-overlay-form {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .8);
    z-index: 999;

    div {
      height: 100%;
      border: 1px dashed var(--v-accent-base);
      background: rgba(0, 0, 0, 0.2);
      pointer-events: none;
    }
  }
}

.upload-draw-image-form {
  padding: 32px 8px 22px;
  border: 1px dashed var(--v-gray-30-base);
  border-radius: 2px;
  background-color: var(--v-gray-0-base);

  p {
    margin-bottom: 2px;
  }

  span {
    border-bottom: 1px solid var(--v-accent-base);
  }
}

.draw-mode-colors {
  background-color: var(--v-gray-0-base);

  div {
    position: relative;
    margin: 0 1px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid transparent;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: currentColor;
    }

    &.is-active {
      border-color: var(--v-accent-base);
    }
  }
}

.draw-mode-size {
  .draw-mode-size-dot {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 6px;
    height: 6px;
    font-size: 6px;
    border-radius: 50%;
    background-color: var(--v-gray-60-base);
  }

  .draw-mode-size-dot:nth-child(3) {
    left: auto;
    top: 5px;
    right: 10px;
    width: 12px;
    height: 12px;
  }

  &.text-size {
    padding: 0 26px 0 18px;

    .draw-mode-size-dot {
      top: 8px;
      width: 10px;
      height: 10px;

      &::before {
        content: '';
        position: absolute;
        right: -100%;
        width: 100%;
        height: 2px;
        background-color: var(--v-gray-60-base);
      }
    }

    .draw-mode-size-dot:nth-child(3) {
      left: auto;
      top: 4px;
      right: 10px;
      width: 18px;
      height: 18px;
      font-size: 13px;

      &::before {
        right: auto;
        left: -100%;
      }
    }

    ::v-deep .v-slider--horizontal {
      min-height: 18px;
    }
  }

  ::v-deep .v-slider--horizontal {
    min-height: 14px;
  }

  ::v-deep .v-slider__thumb {
    z-index: 2;
  }
}

:deep(.fullscreen-control) {
  position: absolute;
  top: 10px;
  right: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  z-index: 4;
}

.zoom-controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  z-index: 4;

  .v-btn {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    background: #ffffff;
  }
}

.view-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  margin-left: 0 !important;
  padding-right: 12px;
  background: #ffffff;

  .file-view-wrapper {
    margin-left: 0;
  }

  .view-gallery {
    left: 4px;
    padding: 16px 0;
  }
}
.unavailable-module {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>

<style lang="scss">
.ui-menu-content.draw-controls-menu {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25) !important;
  border-color: var(--v-gray-30-base);
  border-radius: 5px !important;
}
</style>
